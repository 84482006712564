define("discourse/plugins/discourse-patreon/discourse/routes/admin-plugins-patreon", ["exports", "discourse/lib/ajax-error", "discourse/models/group", "discourse/lib/ajax", "discourse/plugins/discourse-patreon/discourse/models/filter-rule", "discourse/routes/discourse", "rsvp"], function (_exports, _ajaxError, _group, _ajax, _filterRule, _discourse, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* We use three main model to get this page working:
   *  Discourse Groups (excluding the automatic ones), Patreon rewards and
   *  and current filters (one filter is a tuple between 1 Discourse group and N Patreon rewards)
   */
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _rsvp.Promise.all([(0, _ajax.ajax)("/patreon/list.json"), _group.default.findAll({
        ignore_automatic: true
      })]).then(_ref => {
        let [result, groups] = _ref;
        groups = groups.map(g => {
          return {
            id: g.id,
            name: g.name
          };
        });
        return {
          filters: result.filters,
          rewards: result.rewards,
          last_sync_at: result.last_sync_at,
          groups
        };
      }).catch(_ajaxError.popupAjaxError);
    },
    setupController: function (controller, model) {
      const rewards = model.rewards;
      const groups = model.groups;
      const filtersArray = Object.entries(model.filters).map(_ref2 => {
        let [k, v] = _ref2;
        const rewardsNames = v.map(r => rewards[r] ? ` $${rewards[r].amount_cents / 100} - ${rewards[r].title}` : "");
        const group = groups.find(g => g.id === parseInt(k, 10));
        return _filterRule.default.create({
          group: group.name,
          rewards: rewardsNames,
          group_id: k,
          reward_ids: v
        });
      });
      controller.setProperties({
        model: filtersArray,
        groups,
        rewards,
        last_sync_at: model.last_sync_at
      });
    }
  });
});